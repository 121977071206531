<template>
    <!-- 学会负责人详情 -->
    <div class="people_page">
        <topHeader></topHeader>
        <div class="page_content">
            <div class="page_f">
                学会负责人
            </div>
            <div class="page_r">
                <div class="page_title">首页 > <span>学会负责人</span></div>
                <div class="people_cont">
                    <div class="people_t">
                        <div class="people_img">
                            <img :src="peoInfo.avatar" alt="">
                        </div>
                        <div class="people_r">
                            <div class="people_name">{{peoInfo.name}}</div>
                            <div>{{ peoInfo.position }}</div>
                        </div>
                    </div>
                    <div class="people_intro">
                        <div class="intro_title">简介</div>
                        <div class="cont" v-html="peoInfo.intro">
                        </div>
                    </div>
                    <div class="people_achi">
                        <div class="intro_title">成就</div>
                        <div class="cont" v-html="peoInfo.achievement">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import axios from '../http/axios';
    export default {
        data(){
            return{
                peoInfo:{}
            }
        },
        mounted(){
            this.getInfo()
        },
        methods:{
            getInfo(){
                axios.get('insider/info?id='+this.$route.params.id).then(res=>{
                    this.peoInfo = res.data
                })
            }
        }
    }
</script>

<style scoped lang='less'>
.page_content{
    display: flex;
    justify-content: space-between;
    width: 1140px;
    min-width: 1140px;
    margin: 20px auto 30px;
    .page_f{
        width: 270px;
        height: 52px;
        font-size: 16px;
        line-height: 52px;
        color: #fff;
        padding-left: 27px;
        background-color: #1D7FD7;
    }
    .page_r{
        width: 844px;
        .page_title{
            font-size: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #1D7FD7;
            span{
                color: #1D7FD7;
            }
        }
        .people_t{
            display: flex;
            margin-top: 20px;
            margin-bottom: 39px;
            .people_img{
                width: 180px;
                height: 225px;
                margin-right: 42px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .people_r{
                .people_name{
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 24px;
                    color: #000000;
                }
                >div{
                    font-size: 20px;
                    line-height: 20px;
                    color: #272727;
                    margin-bottom: 15px;
                }
            }
        }
        .intro_title{
            font-size: 20px;
            line-height: 20px;
            padding-bottom: 9px;
            border-bottom: 2px solid #D5D5D5;
            margin-bottom: 15px;
        }
        .cont{
            padding-bottom: 10px;
            p{
                font-size: 16px;
                line-height: 18px;
                color: #272727;
                margin-bottom: 20px;
                text-indent: 2em;
            }
        }
    }
}
</style>